import React, { useState, useEffect } from "react"

import Layout from "../components/Layout"

//Icon import
import facebook from "../assets/icons/facebook-black.svg"
import linkedin from "../assets/icons/linkedin-black.svg"
import twitter from "../assets/icons/twitter-black.svg"

const FakeExpensesTR = () => {
  const [url, setUrl] = useState("")

  useEffect(() => {
    if (typeof window !== "undefined") setUrl(window.location)
  })

  return (
    <Layout>
      <div id="blog-details">
        <div id="header">
          <div id="header-details-container">
            <span id="header-details-container-date">Eylül 14, 2021</span>
            <span id="header-details-container-title">Masraf sahteciliği</span>
            <span id="header-details-container-author">
              by <span>Muni</span>
            </span>
          </div>
        </div>

        <div id="blog-body">
          <div id="blog-image" className="body_section">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631612173842.png"
            />
          </div>
          <span className="body_section">Masraf Sahteciliği Nedir?</span>
          <div className="body_image_wrap_right">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631611976993.png"
            />
          </div>

          <span className="body_section">
            Masraf sahteciliği, art niyetli şirket çalışanlarının yapmadığı
            harcamaları şirket adına yapmış gibi göstermek, yapmış olduğu
            harcamaları olduğundan daha yüksek belirtmek veya şahsi
            harcamalarını şirket masrafı olarak göstermek gibi masraf
            raporlarında yapılan birtakım usulsüzlükler bütünüdür.
          </span>
          <span className="body_section">
            Masraf Sahteciliği Bir Sorun Mudur?
          </span>
          <span className="body_section">
            Masraf sahteciliği, ülkemizdeki şirketlerin bir türlü çözüme
            kavuşturamadığı ve her geçen gün giderek büyümekte olan bir
            sorundur. Bu sorun çalışanlarınızın harcamalarını hâlâ elle
            girilerek raporlaması, finans departmanlarınızın verilerde olan
            uyuşmazlığı fark edememesi veya çalışanlarınızın harcamalarını anlık
            olarak takip edememenizden kaynaklanabilir. Masraf sahteciliği,
            doğru önlemleri almadığınız zaman şirketinize ciddi mali zararlar
            verir.
          </span>

          <span className="body_section">
            İngiltere’de Association of Certified Fraud Examiners tarafından
            2018 yılında yapılan bir araştırmaya göre, masraf sahteciliği
            Birleşik Krallık’taki şirketlere yılda 1,9 milyar sterlin zararı
            olduğu sonucu ortaya çıkmıştır. Yine aynı araştırma çalışan
            sahteciliğinin dünya çapında şirketlere her yıl 4 trilyon dolar
            zarara yol açtığı belirlenmiştir – bu rakam tüm şirketlerin yıllık
            gelirinin %5’ine tekabül etmektedir. Ülkemizde ise masraf
            sahteciliğinin, şirketlere yılda ₺350-500 milyon arası zarara yol
            açtığını tahmin etmekteyiz.{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.manchestereveningnews.co.uk/business/business-news/dodgy-expenses-cost-businesses-billions-14898973"
            >
              [1]
            </a>
          </span>
          <span className="body_section">
            Masraf Sahteciliği’nin Çeşitleri Nelerdir?
          </span>
          <span className="body_section">
            Masraf sahteciliğinin yapılış şekillerini birkaç kategoride
            inceleyebiliriz.
          </span>
          <span className="body_section">1 – Kişisel Harcamalar</span>
          <div className="body_image_wrap_left">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631611990336.png"
            />
            <span className="body_section">
              Çalışanlarınız işle herhangi bir ilgisi bulunmayan tamamen kişisel
              alışverişlerini şirket harcaması adı altında göstererek finans
              departmanınızdan ödeme talep ederler. Bu tip sahtecilik genellikle
              kontrolünüzün en alt seviyede olduğu iş seyahatleri sırasında
              gerçekleşir. Örneğin başka bir şehre iş seyahati için giden
              çalışanınız, yorucu bir iş gününün sonunda fazla mesai yaptığı
              gerekçesiyle ödüllendirilmesi gerektiği kanaatine varıyor.
              Ardından arkadaşlarıyla çıktığı ve işten bağımsız bir akşam
              yemeğini sanki şirket müşterileriyle çıkılmış gibi beyan ederek
              finans departmanınızdan ödemesini alıyor. Bu harcama ise kişisel
              harcama sahteciliğine örnektir.
            </span>
          </div>
          <span className="body_section">
            <br />
            <br />
            <br />
            <br />2 – Hayali Harcamalar
          </span>
          <div className="body_image_wrap_right">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631612003509.png"
            />
          </div>
          <span className="body_section">
            <br />
            <br />
            Çalışanınız, sahte yollarla oluşturduğu fiş ya da fatura ile satın
            almamış olduğu bir şeyi almış, abone olmamış olduğu bir platforma
            üye olmuş, yememiş olduğu bir yemeği yemiş veya binmemiş olduğu bir
            taksiyi binmiş gibi gösterebilir. Bunun sonucu olarak şirketiniz bir
            kez daha masraf sahteciliğine kurban giderek çalışanınıza ödeme
            yapacaktır. Diyelim ki şirketinizin bir çalışanı şehir içi
            seyahatlerini taksiyle yapıyor ve taksilerden topladığı fişleri
            finans departmanınıza ay sonunda raporluyor. Ancak bu çalışan sadece
            kendi fişleriyle kalmıyor, çeşitli yerlerden edinmiş olduğu taksi
            fişlerini de şirketinize yol masrafı olarak sunuyor. Bu tip bir
            harcama ise hayali harcama sahteciliğine örnektir.
          </span>
          <span className="body_section">
            {" "}
            <br />
            <br />3 – Şişirilmiş Harcamalar
          </span>
          <div className="body_image_wrap_left">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631612015924.png"
            />
          </div>
          <span className="body_section">
            Son olarak şişirilmiş harcamalar üstünde duralım. Çalışanınız şirket
            harcaması adı altında fatura alırken yaptığı harcamadan çok daha
            yüksek bir miktarda fatura kestirebilir. Çalışanınız, ona sağlamış
            olduğunuz şirket aracına gerçekte olduğundan daha fazla yakıt
            masrafı yapmış olduğunu iddia edebilir ya da konaklama masraflarını
            olduğundan çok daha yüksek miktarda gösterebilir. Örneğin bir
            çalışanınızı iş fuarına göndermeniz gerekiyor. Çalışanınız kendisine
            uygun uçuşu bulduktan sonra almış olduğu fatura üzerinde çeşitli
            bilgisayar programları sayesinde ufak değişikler yaparak bilet
            fiyatını arttırıyor. Bunun sonucu olarak finans departmanınızdan
            biletin olduğundan çok daha fazla bir miktarda ödemesini alabiliyor.
            Bu tip bir harcama ise şişirilmiş harcama sahteliğine örnektir.
          </span>
          <span className="body_section">
            Yukarda belirtmiş olduğumuz masraf sahteciliği yöntemleri, genel
            olarak art niyetli çalışanlarınız tarafından gerçekleştirilmektedir.
            Ancak bazı durumlarda çalışanlarınızın veya finans
            departmanlarınızın masraf raporlarını elle girerken yapabileceği
            yanlış miktar yazımları veya çift talep işleme gibi hatalardan
            dolayı da şirketinize fazladan masraflar çıkabilmektedir.
          </span>
          <span className="body_section">
            Masraf Sahteciliği Önlenebilir Mi?
          </span>
          <span className="body_section">
            Maalesef, masraf sahteciliğini önlemek şirketler için ciddi bir
            mücadele. Halihazırda harcama raporlarıyla günlerce hatta aylarca
            uğraşmak, finans departmanlarınız ve yöneticileriniz için sıkıntılı
            zaten çok sıkıntılı bir süreç. Bunun üstüne sahte raporlarla
            uğraşmak, masraf sahteciliği yapan çalışanlarınızı tespit etmek için
            uğraşan finans departmanlarınızın ve yöneticilerinizin işlerini
            epeyce zora sokacaktır. Yine yukarıda belirtiğimiz araştırmaya göre
            çalışanların yaptığı sahteciliği finans departmanların tespit etme
            süresi 24 aya kadar çıkabilmektedir. Şüpheli işlemi tespit etmiş
            olsanız bile, eğer çalışanlarınız ortak bir kart üzerinden
            harcamalarını yapıyorlarsa sahteciliği yapan çalışanı bulmak hayli
            zor olacaktır.
          </span>
          <span className="body_section">
            Bundan dolayı tüm bu çaba ve mücadelelere rağmen masraf
            sahteciliğine karışan dolandırıcıların yakalanma oranı sadece %17
            olarak kayıtlara geçmiştir. Bunun en büyük sebepleri arasında masraf
            sahteciliğinin genellikle şirketlerdeki en güvenilir insanlar
            tarafından gerçekleştirilme eğilimi gösterilmektedir. Masraf
            sahteciliği %19 oranıyla en çok eğlence, daha sonra %17,1 oranıyla
            seyahat ve %16,7 oranıyla yemek sektöründe gerçekleşmektedir.
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.netsuite.com/portal/business-benchmark-brainyard/industries/articles/cfo-central/expense-report.shtml"
            >
              [2]
            </a>
          </span>
          <span className="body_section">
            Ancak sizlere güzel bir haberimiz var…
          </span>
          <span className="body_section">
            Masraf sahteciliği sorunlarından artık korkmanıza gerek yok!
          </span>
          <span className="body_section">
            Muni masraflara harcama zamanı, miktarı ve kategorisine göre dinamik
            limitler belirleyip özelleştirilebilen bildirimler almanızı sağlar.
            Masrafların fiş ve faturalarını yapay zeka kullanarak çalışan
            beyanlarıyla karşılaştırır. Uyuşmazlıkları çalışanın yöneticisine
            haber verir. Aynı zamanda bir masrafın oluşumundan onayına kadar
            olan süreçte geçirdiği değişiklikleri masraf geçmişinde tutarak
            yöneticilere masraflar üzerinde tam şeffaflık ve kontrol sağlar.
          </span>
          <div className="body_image">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631612042222.png"
            />
          </div>
          <span className="body_section">
            Muni ile masraf sahteciliğinin önüne geçmek artık sizin elinizde!
          </span>
          <span className="body_section">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.manchestereveningnews.co.uk/business/business-news/dodgy-expenses-cost-businesses-billions-14898973"
            >
              [1]
            </a>
            Greer, S. (2018, July 12). Dodgy expenses claims are costing
            businesses £2 billion a year. Manchester Evening News.
          </span>
          <span className="body_section">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.businesswire.com/news/home/20160329005218/en/Expense-Fraud-Costs-U.S.-Employers-2.8-Billion-per-Year-Shows-Chrome-River-Survey"
            >
              [2]
            </a>{" "}
            Wheatcroft, Tim. “Expense Fraud COSTS U.S. EMPLOYERS $2.8 Billion
            per Year, Shows CHROME River Survey.” Expense Fraud Costs U.S.
            Employers $2.8 Billion per Year, Shows Chrome River Survey |
            Business Wire, March 29, 2016.
          </span>
        </div>

        <div id="tags-container">
          <span id="tags-container-title">Tags:</span>
          <div id="tag-container">
            <div className="tag_button">
              <span>Muni</span>
            </div>
            <div className="tag_button">
              <span>Expenses</span>
            </div>
            <div className="tag_button">
              <span>Expense Managements</span>
            </div>
          </div>
        </div>

        <hr />

        <div id="social-container">
          <span>Share:</span>
          <div id="buttons-container">
            <div className="social_button">
              <img src={linkedin} alt="Linkedin" />
            </div>
            <div className="social_button" onClick={() => {}}>
              <img src={facebook} alt="Facebook" />
            </div>
            <div
              className="social_button"
              onClick={() => {
                const text = `Masraf sahteciliği\n${window.location.href}`
                window.open(
                  `https://twitter.com/intent/tweet?text=${text}`,
                  "_blank"
                )
              }}
            >
              <img src={twitter} alt="Twitter" />
            </div>
          </div>
        </div>

        <div id="about-author-wrapper">
          <div id="about-author-card">
            <div id="about-author-card-top">
              <div id="about-author-card-top-avatar">
                <img
                  alt="author avatar"
                  src="https://media-exp1.licdn.com/dms/image/C4E0BAQGIWKhm4dCqIA/company-logo_200_200/0/1609746202791?e=1656547200&v=beta&t=1T2uU6-cLPmvNzB9pNn0-sC7U1H3-fmYUPsG-BUeAmo"
                />
              </div>
              <div id="about-author-card-top-info">
                <span>Muni</span>
              </div>
            </div>
            <div id="about-author-card-middle">About Author</div>

            <span id="about-author-card-bottom">
              Muni is a fintech start-up working to revolutionize the way for
              employees to make business expenditures and companies to manage
              their expenses. We are marrying smart company cards with advanced
              expense management software so that expenses become a breeze for
              companies that suffer millions of dollars in expense management
              costs every year.
            </span>
          </div>
        </div>

        <div id="leave-comment"></div>
      </div>
    </Layout>
  )
}

export default FakeExpensesTR
